import avatar from './avatar.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Hey There! This is work in progress
        </h1>
        <img src={avatar} className="App-logo" alt="logo" />
        <p>
          This site is still under construction. Please return later. Thanks!
        </p>
        <p>
          -James
        </p>
      </header>
    </div>
  );
}

export default App;
